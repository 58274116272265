import React from "react"
import logo from '../assets/logo.svg';
import portfolio from '../assets/AliFelski-SamplePortfolio.pdf';
import Layout from "../components/layout"
import '../components/home.scss';

export default function Home() {
  return (
    <Layout>
      <div className="header-home">
        <div className="row">
          <div className="header-content-wrapper">
            <img className="logo" src={logo} alt="Ali Felski Design" />
            <div>
              <h1 className="header--xlarge header-title-home">Design, UX, Illustration & Design Direction</h1>
              <span className="callout">Creating highly usable experiences for companies large and small.</span>
            </div>
            <div>
              <a className="button button-group" href="mailto:alifelski@gmail.com">Say Hello!</a>
              <a className="button button-group" href={portfolio} download>Download Sample Portfolio</a>
              <a className="button" target="_blank" href="https://www.linkedin.com/in/alexandra-felski-6626464/">Resume</a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
